<template>
  <div>
    <Header />
    <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
    <div class="container">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-xl-12">
                <div class="product-detail">
                  <div class="tab-content" id="v-pills-tabContent">
                    <div
                      class="tab-pane fade active show"
                      id="product-4"
                      role="tabpanel"
                    >
                      <div class="product-img" style="padding-left:5px;padding-right:0px">
                        <div class="row">
                          <div class="col-12">
                            <h1 style="font-size: 48px">POWERFLOW <span style="font-size:10px;">A powerful wave soldering system with an individual preheating concept and flexible solder bath technology and an attractive price-performance ratio.</span> </h1>
                          </div>
                        </div>
                        <img
                          src="../../../../assets/images/product/main/production_equipement/Wave_Soldering/5csm_Ersa-Wave-POWERFLOW-01-900x675_56d1c7c31f.png"
                          alt="img-4"
                          class="img-fluid mx-auto d-block"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <!-- end product img -->
              </div>
            </div>
            <!-- end row -->
            <br /><br /><br /><br /><br /><br /><br /><br /><br />
            <div class="row">
              <div class="col-xl-6">
                <div class="product-detail">
                  <div class="tab-content" id="v-pills-tabContent">
                    <div
                      class="tab-pane fade active show"
                      id="product-4"
                      role="tabpanel"
                    >
                      <div class="product-img">
                        <img
                          src="../../../../assets/images/product/main/production_equipement/Wave_Soldering/14csm_Kurtz-Ersa-Technology-Wavesoldering-Header-900x675_7b8fce4c01.png"
                          alt="img-4"
                          class="img-fluid mx-auto d-block"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <!-- end product img -->
              </div>
              <div class="col-xl-6">
                <br /><br />
                <div class="mt-4">
                  <h1>Tech-Highlights POWERFLOW</h1>
                  <br />
                  <ul class="container">
                    <li>
                      <h3>
                        Powerful wave soldering system with attractive price/performance ratio
                      </h3>
                    </li>
                    <br />
                    <li>
                      <h3>
                       Modular, flexible and individually expandable preheating concept - can be configured variably in terms of length and output (can also be retrofitted)
                      </h3>
                    </li>
                    <br />
                    <li>
                      <h3>
                       Monitoring and documentation of all <br> process-relevant data > ready for traceability
                      </h3>
                    </li>
                   
                    <br />
                  </ul>
                </div>
              </div>
            </div>
            <!-- end row -->


            <br /><br /><br /><br /><br /><br /><br /><br />

            <div class="row">
              <div class="col-8" style="margin-left: 50px">
                <h1><strong>Technical data</strong></h1>
              </div>
              <div class="col-3"></div>
            </div>
            <br />
            <b-tabs pills card vertical style="font-size: 18px">
              <b-tab active title="Dimensions">
                <div class="row" style="margin-bottom: 5px; margin-left: 10%">
                  <div class="col">
                    <h4><strong>Length : </strong>  from 4,200 to 4,950 mm</h4>
                  </div>
                  <div class="col">
                    <h4><strong>Height :</strong>  1,565 mm</h4>
                  </div>
                </div>
                <div class="row" style="margin-left: 10%">
                  <div class="col">
                    <h4><strong>Width : </strong> 1,510 mm</h4>
                  </div>
                  <div class="col">
                   <h4><strong>Weight : </strong>  2,200 to 3,000 kg</h4>
                   </div>
                </div>
              </b-tab>

              <b-tab title="Conveyor systems">
                <div class="row" style="margin-left: 10%">
                  <div class="col">
                    <h4>Finger conveyor</h4>
                  </div>
                  <div class="col">
                    <h4>Maximum working width up to 508 mm</h4>
                  </div>
                </div>
                <div class="row" style="margin-left: 10%">
                  <div class="col"><h4>Maximum conveyor speed 2.5 m/min</h4></div>
                  <div class="col">
                    <h4>
                      Maximum height of components, top of board: up to 120 mm
                    </h4>
                  </div>
                </div>
              </b-tab>
              <b-tab title="Preheating / Solder Module">
                <div class="row" style="margin-left: 10%">
                  <div class="col">
                    <h4>
                      <strong>Preaheat module bottom-side : </strong>
                      </h4>
                  </div>
                  <div class="col">
                    <h4>
                      <strong> Solder module : </strong> 
                    </h4>
                  </div>
                </div>

                <div class="row" style="margin-left: 10%">
                  <div class="col">
                    <h4>  Dynamic short-wave emitter - max. 10.4 kW (power controlled)</h4>
                  </div>
                  <div class="col">
                    <h4>
                      Capacity: ca. 9.8 kW
                    </h4>
                  </div>
                </div>
                <div class="row" style="margin-left: 10%">
                  <div class="col">
                    <h4>  Medium-wave emitter, max. 6 kW (controlled)</h4>
                  </div>
                  <div class="col">
                    <h4>
                      Solder volume: approx. 820 kg lead-free alloy
                    </h4>
                  </div>
                </div>
                <div class="row" style="margin-left: 10%">
                  <div class="col">
                    <h4>  Dimensions: 600 mm length each</h4>
                  </div>
                  <div class="col">
                    <h4>
                      Solder temperature: max. 300 °C
                    </h4>
                  </div>
                </div>
              </b-tab>
            </b-tabs>

            <br /><br /><br /><br /><br />
            <div class="row">
              <div class="col-xl-12">
                <div class="">
                  <div class="">
                    <div class="row">
                      <div class="col-xl-7">
                        <!-- 16:9 aspect ratio -->
                        <div class="ratio ratio-21x9">
                          <iframe
                            style="width: 600px; height: 300px"
                            title="YouToube Video" 
                            src="https://www.youtube.com/embed/M2YSmv938vQ" 
                            allowfullscreen="" 
                          ></iframe>
                        </div>
                      </div>
                      <div class="col-xl-5">
                        <br /><br />
                        <h1>Ersa POWERFLOW: In a class of its own</h1>
                        <h4>
                         A powerful wave soldering system with an individual preheating concept and flexible solder bath technology and an attractive price-performance ratio.
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- end col -->
            </div>
          </div>
        </div>
        <!-- end card -->
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import Header from "../../../../components/Header.vue";

import Footer from "../../../../components/Footer.vue";

/**
 * Dashboard component
 */
export default {
  components: {
    Header,

    Footer,
  },
  methods: {
    onSlideStart() {
      this.sliding = true;
    },
    onSlideEnd() {
      this.sliding = false;
    },
  },
};
</script>